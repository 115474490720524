import { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router";
import styles from "styles/session-therapies.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import EnrollmentService from "services/EnrollmentService.js";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import useAPI from "hooks/useAPI.js";
import PopUpModal from "components/utils/PopUpModal.js";
import { useOutletContext } from "react-router-dom";


// Define the desired order of therapies
const desiredOrder = [
  "Palmer",
  "Rooting",
  "Snout",
  "Babinsky",
  "ATNR",
  "STNR",
  "Spinal Galant",
  "Supine",
  "Lateral",
  "Prone",
  "Push Ups",
  "Pull Ups",
  "Curl Ups",
  "Vestibular",
  "Smell",
  "Occulomotor",
  "MetroTimer",
  "TENS",
  "Transcranial",
  "Eyelights",
  "PONS",
  "Interactive Metronome",
  "Neurosage",
  "Laser"
];

const protocolCharts = [
  { therapyTypeName: "Palmer", imageSrc: "palmer.png" },
  { therapyTypeName: "Rooting", imageSrc: "rooting-snout.png" },
  { therapyTypeName: "Babinsky", imageSrc: "babinsky.png" },
  { therapyTypeName: "ATNR", imageSrc: "atnr.png" },
  { therapyTypeName: "STNR", imageSrc: "stnr.png" },
  { therapyTypeName: "Spinal Galant", imageSrc: "spinal-galant.png" },
  { therapyTypeName: "Supine", imageSrc: "supine.png" },
  { therapyTypeName: "Lateral", imageSrc: "lateral.png" },
  { therapyTypeName: "Prone", imageSrc: "prone.png" },
  { therapyTypeName: "Push Ups", imageSrc: "push-ups.png" },
  { therapyTypeName: "Vestibular", imageSrc: "vestibular.png" },
  { therapyTypeName: "Smell", imageSrc: "smells.png" },
  { therapyTypeName: "Occulomotor", imageSrc: "occulomotor.png" },
  { therapyTypeName: "MetroTimer", imageSrc: "metrotimer.png" },
  {
    therapyTypeName: "Transcranial",
    imageSrc: "transcranial.png",
  },
  {
    therapyTypeName: "Eyelights",
    imageSrc: "eye-lights.png",
  },
  {
    therapyTypeName: "PONS",
    imageSrc: "pons.png",
  },
  {
    therapyTypeName: "Interactive Metronome",
    imageSrc: "interactive-metronome.png",
  },
  { therapyTypeName: "Neurosage", imageSrc: "neurosage.png" },
  { therapyTypeName: "Brachiation", imageSrc: "pull-ups.png" },
  { therapyTypeName: "Curl Ups", imageSrc: "curl-ups.png" },
  { therapyTypeName: "Snout", imageSrc: "rooting-snout.png" },
];


function SessionTherapies() {
  let navigate = useNavigate();
  const popUpModalRef = useRef(null);

  const [hospitalId] = useOutletContext();
  const { patientId } = useParams();
  const { enrollmentId } = useParams();
  const { sessionId } = useParams();

  const [sessionTherapies, setSessionTherapies] = useState([]);
  const [sessionsTherapiesHistory, setSessionsTherapiesHistory] = useState([]);

  const [protocolChartImageSrc, setProtocolChartImageSrc] = useState("");

  const getSessionTherapiesAPI = useAPI(
    EnrollmentService.getSessionTherapies,
    getSessionTherapiesHandler
  );
  const getSessionsTherapiesHistoryAPI = useAPI(
    EnrollmentService.getSessionsTherapiesHistory,
    getSessionsTherapiesHistoryHandler
  );
  const saveSessionAPI = useAPI(EnrollmentService.saveSessionTherapy, () => { });
  const submitSessionAPI = useAPI(
    EnrollmentService.submitEnrollmentSession,
    submitSessionHandler,
    true
  );

  function getSessionTherapies() {
    getSessionTherapiesAPI.execute(
      hospitalId,
      patientId,
      enrollmentId,
      sessionId
    );
  }

  function getSessionsTherapiesHistory() {
    getSessionsTherapiesHistoryAPI.execute(
      hospitalId,
      patientId,
      enrollmentId,
      sessionId
    );
  }

  function saveSession(sessionTherapy) {
    saveSessionAPI.execute(hospitalId, patientId, enrollmentId, sessionId, {
      enrollmentTherapyTypeName: sessionTherapy.enrollmentTherapyTypeName,
      score: sessionTherapy.score,
      level: sessionTherapy.level,
      levelOverrideReason: sessionTherapy.levelOverrideReason,
      seconds: sessionTherapy.seconds,
      coachComment: sessionTherapy.coachComment,
    });
  }

  function submitEnrollmentSession() {
    submitSessionAPI.execute(hospitalId, patientId, enrollmentId, sessionId);
  }


  useEffect(() => {
    getSessionTherapies();
    getSessionsTherapiesHistory();
  }, []);

  /* API Success Handlers */

  function getSessionTherapiesHandler(response) {
    let data = response.data;

    // Sort the data based on the desired order
    data.sort((a, b) => {
      return (
        desiredOrder.indexOf(a.enrollmentTherapyTypeName) -
        desiredOrder.indexOf(b.enrollmentTherapyTypeName)
      );
    });

    setSessionTherapies(data);
  }

  function getSessionsTherapiesHistoryHandler(response) {
    let data = response.data;
    setSessionsTherapiesHistory(data);
  }

  function submitSessionHandler(response) {
    navigate(-1);
  }

  function viewProtocolChart(therapyTypeName) {
    const chart = protocolCharts.find(
      (obj) => obj.therapyTypeName === therapyTypeName
    );
    setProtocolChartImageSrc(chart.imageSrc);
    popUpModalRef.current.openModal();
  }

  return (
    <Paper elevation={0} className="p-4">
      <div className="d-flex justify-content-between">
        <Typography variant="h2" gutterBottom>
          Session Therapies
        </Typography>
        <Button onClick={() => navigate(-1)} variant="outlined">
          Back
        </Button>
      </div>
      <form>
        {getSessionTherapiesAPI.status === "pending" ? (
          <div className="text-center">
            <CircularProgress
              className="text-center"
              sx={{
                my: 20,
              }}
            />
          </div>
        ) : (
          <div className="mt-4">
            <div class="mb-4">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() => {
                  setProtocolChartImageSrc("primitive-reflex-simulation.png");
                  popUpModalRef.current.openModal();
                }
                }
              >
                View Primitive Reflex Chart
              </button>
            </div>
            <div className="mb-4">
              {sessionTherapies.map((therapy, index) => {
                return (
                  <TherapyCard
                    key={index}
                    therapy={therapy}
                    history={sessionsTherapiesHistory[index]}
                    saveSessionHandler={saveSession}
                    viewProtocolChart={viewProtocolChart}
                  />
                );
              })}
            </div>
            {submitSessionAPI.status === "pending" ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                className="me-3"
              >
                Submit
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                className="me-3"
                startIcon={<DoneIcon />}
                variant="contained"
                onClick={submitEnrollmentSession}
              >
                Submit
              </Button>
            )}
          </div>
        )}
      </form>
      <PopUpModal ref={popUpModalRef}>
        <img
          src={`/user-forms/doctor-assessment/protocol-charts/${protocolChartImageSrc}`}
          alt="Protocol Chart"
          class="img-fluid"
        />
      </PopUpModal>
    </Paper>
  );
}

function TherapyCard({
  therapy,
  history,
  saveSessionHandler,
  viewProtocolChart,
}) {
  const theme = useTheme();
  const [therapyState, setTherapyState] = useState(therapy);

  const onInputChangeHandler = (e) => {
    let name = e.target.name.split("-")[0];
    let value = e.target.value;
    setTherapyState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (therapy !== therapyState) saveSessionHandler(therapyState);
  }, [therapyState]);

  return (
    <Paper
      elevation={1}
      sx={{
        background: theme.palette.background.default,
      }}
      className="p-4 mb-3"
    >
      <Stack direction="row" justifyContent="space-between">
        <div>
          {" "}
          <Typography color="text.dark" variant="h3" className="d-inline me-3">
            {therapy.enrollmentTherapyTypeName}
          </Typography>
          {protocolCharts.some(
            (obj) => obj.therapyTypeName === therapy.enrollmentTherapyTypeName
          ) ? (
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              onClick={() =>
                viewProtocolChart(therapy.enrollmentTherapyTypeName)
              }
            >
              View Chart
            </button>
          ) : null}
        </div>

        <div>
          <Typography color="text.secondary" variant="h5">
            Current Level
          </Typography>
          <Typography variant="h2" className="mt-1 text-center">
            <Chip color="info" label={therapy.level} />
          </Typography>
        </div>
      </Stack>
      <Typography
        color="text.secondary"
        variant="subtitle1"
        component="span"
        className="me-4"
      >
        Score
      </Typography>
      <div className="mt-1">
        <div className={styles["score-input"]}>
          <input
            className="form-check-input"
            type="radio"
            name={"score-" + therapy.id}
            id={"scoreInput-" + therapy.id + "-Plus"}
            value="+"
            onChange={onInputChangeHandler}
            checked={therapyState.score === "+"}
          />
          <label
            className="form-check-label"
            htmlFor={"scoreInput-" + therapy.id + "-Plus"}
          >
            +
          </label>
        </div>
        <div className={styles["score-input"]}>
          <input
            className="form-check-input"
            type="radio"
            name={"score-" + therapy.id}
            id={"scoreInput-" + therapy.id + "-Minus"}
            value="-"
            onChange={onInputChangeHandler}
            checked={therapyState.score === "-"}
          />
          <label
            className="form-check-label"
            htmlFor={"scoreInput-" + therapy.id + "-Minus"}
          >
            -
          </label>
        </div>
        <div className={styles["score-input"]}>
          <input
            className="form-check-input"
            type="radio"
            name={"score-" + therapy.id}
            id={"scoreInput-" + therapy.id + "-Skip"}
            value="S"
            onChange={onInputChangeHandler}
            checked={therapyState.score === "S"}
          />
          <label
            className="form-check-label"
            htmlFor={"scoreInput-" + therapy.id + "-Skip"}
          >
            Skip
          </label>
        </div>
      </div>
      <div className="row mb-4 mt-4">
        <div className="col col-2">
          <label htmlFor={"levelInput-" + therapy.id} className="form-label">
            Level Override
          </label>
          <select
            className="form-select"
            name={"level-" + therapy.id}
            id={"levelInput-" + therapy.id}
            aria-label="Default select example"
            onChange={onInputChangeHandler}
            value={therapyState.level}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        <div className="col col-4">
          <label
            htmlFor={"levelOverrideReasonInput-" + therapy.id}
            className="form-label"
          >
            Override Reason
          </label>
          <input
            type="text"
            name={"levelOverrideReason-" + therapy.id}
            className="form-control"
            id={"levelOverrideReasonInput-" + therapy.id}
            onChange={onInputChangeHandler}
            value={
              therapyState.levelOverrideReason !== null
                ? therapyState.levelOverrideReason
                : ""
            }
          />
        </div>
        <div className="col col-2">
          <label htmlFor={"secondsInput-" + therapy.id} className="form-label">
            Seconds
          </label>
          <input
            type="number"
            name={"seconds-" + therapy.id}
            className="form-control"
            id={"secondsInput-" + therapy.id}
            onChange={onInputChangeHandler}
            value={therapyState.seconds !== null ? therapyState.seconds : ""}
          />
        </div>
        <div className="col col-4">
          <label
            htmlFor={"coachCommentInput-" + therapy.id}
            className="form-label"
          >
            Coach Comment
          </label>
          <select
            className="form-select"
            name={"coachComment-" + therapy.id}
            id={"coachCommentInput-" + therapy.id}
            aria-label="Default select example"
            onChange={onInputChangeHandler}
            value={
              therapyState.coachComment !== null
                ? therapyState.coachComment
                : ""
            }
          >
            <option value=""></option>
            <option value="Late">Late</option>
            <option value="Extra Assistance/Improper Form">
              Extra Assistance/Improper Form
            </option>
            <option value="Equipment Failure">Equipment Failure</option>
            <option value="Change of Coach/Time Slot">
              Change of Coach/Time Slot
            </option>
            <option value="New Partner">New Partner</option>
            <option value="Modified Protocal">Modified Protocal</option>
            <option value="Refusal To Perform">Refusal To Perform</option>
            <option value="Distractive/Hyperactive">
              Distractive/Hyperactive
            </option>
            <option value="Avoidance">Avoidance</option>
          </select>
        </div>
      </div>
      {history === undefined || history.length === 0 ? null : (
        <div>
          <Typography color="text.secondary" variant="subtitle1">
            History
          </Typography>
          <Paper elevation={0} className="px-4 py-3 mt-1">
            <div className="d-flex flex-row">
              <Typography
                color="text.default"
                variant="subtitle2"
                component="span"
                className={styles["history-sub-label"]}
              >
                Session
              </Typography>
              <Stack direction="row" spacing={3}>
                {history.map((sessionTherapy, index) => {
                  return (
                    <Typography
                      key={"session-history-" + index}
                      color="text.secondary"
                      variant="h4"
                      component="span"
                      className={styles["history-session-val"]}
                    >
                      {index}
                    </Typography>
                  );
                })}
              </Stack>
            </div>
            <div className="d-flex flex-row mt-2">
              <Typography
                color="text.default"
                variant="subtitle2"
                component="span"
                className={styles["history-sub-label"]}
              >
                Score
              </Typography>
              <Stack direction="row" spacing={3}>
                {history.map((sessionTherapy, index) => {
                  return (
                    <Box
                      key={"score-history-" + index}
                      component="span"
                      className={styles["history-score-val"]}
                      sx={{
                        backgroundColor: getScoreBgColor(sessionTherapy.score),
                      }}
                    >
                      <Typography color="white" variant="h3" component="span">
                        {sessionTherapy.score !== null
                          ? sessionTherapy.score
                          : ""}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </div>
            <div className="d-flex flex-row mt-2">
              <Typography
                color="text.default"
                variant="subtitle2"
                component="span"
                className={styles["history-sub-label"]}
              >
                Level
              </Typography>
              <Stack direction="row" spacing={3}>
                {history.map((sessionTherapy, index) => {
                  return (
                    <Typography
                      key={"level-history-" + index}
                      color="text.secondary"
                      variant="h4"
                      component="span"
                      className={styles["history-level-val"]}
                    >
                      {sessionTherapy.level}
                    </Typography>
                  );
                })}
              </Stack>
            </div>
          </Paper>
        </div>
      )}
    </Paper>
  );
}

function getScoreBgColor(score) {
  if (score === "+") {
    return "success.main";
  } else if (score === "-") {
    return "error.main";
  } else if (score === "Skip") {
    return "primary.main";
  } else {
    return "white";
  }
}

export default SessionTherapies;
