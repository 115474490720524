import ApiBaseService from "services/ApiBaseService.js";

class EnrollmentService {
  static createEnrollment([hospitalId, patientId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments`,
      "POST"
    );
  }

  static getPatientEnrollments([hospitalId, patientId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments`,
      "GET"
    );
  }

  static deleteEnrollment([hospitalId, patientId, enrollmentId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}`,
      "DELETE"
    );
  }

  static createEnrollmentSession([hospitalId, patientId, enrollmentId, sessionType]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions?sessionType=${sessionType}`,
      "POST"
    );
  }

  static getEnrollmentSessions([hospitalId, patientId, enrollmentId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions`,
      "GET"
    );
  }

  static getSessionsTherapiesHistory([
    hospitalId,
    patientId,
    enrollmentId,
    sessionId,
  ]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions/${sessionId}/history`,
      "GET"
    );
  }

  static getSessionTherapies([hospitalId, patientId, enrollmentId, sessionId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions/${sessionId}/therapies`,
      "GET"
    );
  }

  static getSessionAcademics([hospitalId, patientId, enrollmentId, sessionId]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions/${sessionId}/academics`,
      "GET"
    );
  }

  static saveSessionTherapy([
    hospitalId,
    patientId,
    enrollmentId,
    sessionId,
    data,
  ]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions/${sessionId}/therapies`,
      "PUT",
      data
    );
  }

  static saveSessionAcademic([
    hospitalId,
    patientId,
    enrollmentId,
    sessionId,
    data,
  ]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions/${sessionId}/academics`,
      "PUT",
      data
    );
  }

  static submitEnrollmentSession([
    hospitalId,
    patientId,
    enrollmentId,
    sessionId,
  ]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions/${sessionId}`,
      "POST"
    );
  }

  static deleteEnrollmentSession([
    hospitalId,
    patientId,
    enrollmentId,
    sessionId,
  ]) {
    return ApiBaseService.request(
      `hospitals/${hospitalId}/patients/${patientId}/enrollments/${enrollmentId}/sessions/${sessionId}`,
      "DELETE"
    );
  }
}

export default EnrollmentService;
