import { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { Button, Paper, Typography, TextField, MenuItem, Select, FormControl, Checkbox, ListItemText, OutlinedInput, CircularProgress } from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";
import useAPI from "hooks/useAPI.js";
import EnrollmentService from "services/EnrollmentService.js";

const academicProgramOptions = [
    { label: 'IXL - Math', value: 'IXL_Math' },
    { label: 'IXL - Language Arts', value: 'IXL_LanguageArts' },
    { label: 'MaxScholar', value: 'MaxScholar' },
    { label: 'CogBuilder', value: 'CogBuilder' },
];

const gradeLevelOptions = [
    'Pre-Kindergarten', 'Kindergarten', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
];

const lessonCompletionOptions = ['Yes (+)', 'No (-)'];
const lessonsCompletedOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8'];
const sessionCriteriaOptions = ['+', '-'];
const behaviorOptions = Array.from({ length: 10 }, (_, i) => (i + 1).toString());
const equipmentOptions = [
    'N/A', 'Eyelights', 'Tens Unit', 'Transcranial', 'Galvanic Device', 'Bone Conducting Headphones',
];

const SessionAcademics = () => {
    let navigate = useNavigate();

    const [hospitalId] = useOutletContext();
    const { patientId } = useParams();
    const { enrollmentId } = useParams();
    const { sessionId } = useParams();

    const [academicPrograms, setAcademicPrograms] = useState([]);
    const [gradeLevels, setGradeLevels] = useState([]);
    const [lessonSkills, setLessonSkills] = useState('');
    const [lessonCompletion, setLessonCompletion] = useState('');
    const [lessonsCompleted, setLessonsCompleted] = useState('');
    const [sessionCriteria, setSessionCriteria] = useState('');
    const [behavior, setBehavior] = useState('');
    const [equipment, setEquipment] = useState([]);
    const [isSaved, setIsSaved] = useState(false);

    const getSessionAcademicsAPI = useAPI(
        EnrollmentService.getSessionAcademics,
        getSessionAcademicsHandler,
    );

    const saveSessionAcademicAPI = useAPI(EnrollmentService.saveSessionAcademic, saveSessionHandler);

    const submitSessionAPI = useAPI(
        EnrollmentService.submitEnrollmentSession,
        submitSessionHandler,
        true
    );

    const handleMultiSelectChange = (setter) => (event) => {
        const { target: { value } } = event;
        setter(typeof value === 'string' ? value.split(',') : value);
    };

    function getSessionAcademics() {
        getSessionAcademicsAPI.execute(
            hospitalId,
            patientId,
            enrollmentId,
            sessionId
        );
    }

    function saveSession(sessionTherapy) {
        saveSessionHandler();
        // saveSessionAcademicAPI.execute(hospitalId, patientId, enrollmentId, sessionId, {
        //     enrollmentTherapyTypeName: sessionTherapy.enrollmentTherapyTypeName,
        //     score: sessionTherapy.score,
        //     level: sessionTherapy.level,
        //     levelOverrideReason: sessionTherapy.levelOverrideReason,
        //     seconds: sessionTherapy.seconds,
        //     coachComment: sessionTherapy.coachComment,
        // });
    }

    const handleSubmit = () => {
        console.log({
            academicPrograms,
            gradeLevels,
            lessonSkills,
            lessonCompletion,
            lessonsCompleted,
            sessionCriteria,
            behavior,
            equipment,
        });
        // submitSessionAPI.execute(hospitalId, patientId, enrollmentId, sessionId);
    };

    function handleInputChange(setter, value) {
        setter(value);
        saveSession({
            academicPrograms,
            gradeLevels,
            lessonSkills,
            lessonCompletion,
            lessonsCompleted,
            sessionCriteria,
            behavior,
            equipment,
        });
    }

    useEffect(() => {
        getSessionAcademics();
    }, []);



    /* API Success Handlers */

    function getSessionAcademicsHandler(response) {
        let data = response.data;

    }

    function saveSessionHandler() {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 2000);
    }

    function submitSessionHandler(response) {
        navigate(-1);
    }


    return (
        <Paper elevation={0} className="p-4">
            <div className="d-flex justify-content-between">
                <Typography variant="h2" gutterBottom>
                    Session Academics
                </Typography>
                <Button onClick={() => navigate(-1)} variant="outlined">
                    Back
                </Button>
            </div>
            {getSessionAcademicsAPI.status === "pending" ? (
                <div className="text-center">
                    <CircularProgress
                        className="text-center"
                        sx={{
                            my: 20,
                        }}
                    />
                </div>
            ) :
                (<form className="mt-2">
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <Typography color="text.secondary" variant="subtitle1" component="span" className="me-4">
                                Academic Programs
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="academicPrograms"
                                    multiple
                                    value={academicPrograms}
                                    onChange={(e) => handleInputChange(setAcademicPrograms, e.target.value)}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {academicProgramOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            <Checkbox checked={academicPrograms.includes(option.value)} />
                                            <ListItemText primary={option.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <Typography color="text.secondary" variant="subtitle1" component="span" className="me-4">
                                Grade Levels
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="gradeLevels"
                                    multiple
                                    value={gradeLevels}
                                    onChange={(e) => handleInputChange(setGradeLevels, e.target.value)}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {gradeLevelOptions.map((level) => (
                                        <MenuItem key={level} value={level}>
                                            <Checkbox checked={gradeLevels.includes(level)} />
                                            <ListItemText primary={level} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-6">
                            <Typography color="text.secondary" variant="subtitle1" component="span" className="me-4">
                                Lesson/Skills Worked on
                            </Typography>
                            <TextField
                                id="lessonSkills"
                                variant="outlined"
                                fullWidth
                                value={lessonSkills}
                                onChange={(e) => handleInputChange(setLessonSkills, e.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <Typography color="text.secondary" variant="subtitle1" component="span" className="me-4">
                                Lesson/s Completion
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="lessonCompletion"
                                    value={lessonCompletion}
                                    onChange={(e) => handleInputChange(setLessonCompletion, e.target.value)}
                                    input={<OutlinedInput />}
                                >
                                    {lessonCompletionOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-6">
                            <Typography color="text.secondary" variant="subtitle1" component="span" className="me-4">
                                Number of Lessons Completed
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="lessonsCompleted"
                                    value={lessonsCompleted}
                                    onChange={(e) => handleInputChange(setLessonsCompleted, e.target.value)}
                                    input={<OutlinedInput />}
                                >
                                    {lessonsCompletedOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <Typography color="text.secondary" variant="subtitle1" component="span" className="me-4">
                                Meet Criteria for Session
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="sessionCriteria"
                                    value={sessionCriteria}
                                    onChange={(e) => handleInputChange(setSessionCriteria, e.target.value)}
                                    input={<OutlinedInput />}
                                >
                                    {sessionCriteriaOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-6">
                            <Typography color="text.secondary" variant="subtitle1" component="span" className="me-4">
                                Behavior
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="behavior"
                                    value={behavior}
                                    onChange={(e) => handleInputChange(setBehavior, e.target.value)}
                                    input={<OutlinedInput />}
                                >
                                    {behaviorOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6">
                            <Typography color="text.secondary" variant="subtitle1" component="span" className="me-4">
                                Equipment
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="equipment"
                                    multiple
                                    value={equipment}
                                    onChange={(e) => handleInputChange(setEquipment, e.target.value)}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {equipmentOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <Checkbox checked={equipment.includes(option)} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {submitSessionAPI.status === "pending" ? (
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            className="me-3"
                        >
                            Submit
                        </LoadingButton>
                    ) : (
                        <div className="d-flex align-items-center">
                            <Button
                                type="button"
                                className="me-3"
                                startIcon={<DoneIcon />}
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                            {isSaved && <Typography color="success.main">Saved</Typography>}
                        </div>
                    )}
                </form>)}
        </Paper>

    );
};

export default SessionAcademics;
