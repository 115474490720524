import { useState, useEffect, useCallback, useRef } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DateUtils from "utils/DateUtils.js";
import PatientService from "services/PatientService.js";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import DATA_TABLE_THEME from "themes/DataTableTheme.js";
import PopUpModal from "components/utils/PopUpModal.js";
import ConfirmActionModal from "components/modals/ConfirmActionModal";
import useAPI from "hooks/useAPI.js";
import FormUtils from "utils/FormUtils";
import FileUtils from "utils/FileUtils";
import { useSelector, useDispatch } from "react-redux";
import { selectHospital, focusHospital } from "state/slices/hospitalSlice";
import Checkbox from "@mui/material/Checkbox";


export default function PatientList() {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const focusedHospital = useSelector(selectHospital);
    const hospitalId = focusedHospital.id;

    const [patientList, setPatientList] = useState([]);
    const popUpModalRef = useRef(null);
    const [archivingPatientId, setArchivingPatientId] = useState("");
    const [pagination, setPagination] = useState({
        page: 0,
        size: 10,
        total: 0,
        search: "",
    });
    const [allCentres, setAllCentres] = useState(false);

    const optimisedSearchHandler = FormUtils.debounce((e) => {
        if (allCentres) {
            fetchPatients(0);
        } else {
            fetchPatients(hospitalId);
        }
    }, 500);

    const fetchPatientsAPI = useAPI(
        PatientService.getAllPatients,
        fetchPatientsHandler,
        false
    );
    const archivePatientAPI = useAPI(
        PatientService.archivePatient,
        archivePatientHandler,
        true
    );
    const sendRegistrationEmailAPI = useAPI(
        PatientService.sendRegistrationEmail,
        () => { },
        true
    );

    const dataTableOptions = {
        filter: false,
        responsive: "standard",
        selectableRows: "none",
        serverSide: true,
        download: true,
        downloadOptions: {
            filename: "Patients.csv",
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },
        onDownload: () => {
            downloadAllPatientsData();
            return false;
        },
        jumpToPage: true,
        count: pagination.total,
        page: pagination.page,
        rowsPerPage: pagination.size,
        searchText: pagination.search,
        searchProps: {
            onKeyUp: optimisedSearchHandler,
        },
        onChangePage: (currentPage) => {
            pagination.page = currentPage;
            setPagination(pagination);
            fetchPatients(hospitalId);
        },
        onChangeRowsPerPage: (rowsPerPage) => {
            pagination.size = rowsPerPage;
            setPagination(pagination);
            fetchPatients(hospitalId);
        },
        onSearchChange: (searchText) => {
            if (searchText) {
                pagination.search = searchText;
            } else {
                pagination.search = "";
            }
            setPagination(pagination);
        },
        onSearchClose: () => {
            if (pagination.search !== "") {
                pagination.search = "";
                setPagination(pagination);
                fetchPatients(hospitalId);
            }
        },
    };

    useEffect(() => {
        setAllCentres(false);

        fetchPatients(hospitalId);
    }, [hospitalId]);

    const fetchPatients = useCallback((hspId) => {
        fetchPatientsAPI.execute(
            hspId,
            pagination.page,
            pagination.size,
            pagination.search
        );
    }, [fetchPatientsAPI]);

    async function downloadAllPatientsData() {
        const response = await PatientService.getAllPatients([hospitalId]);

        let patientsList = response.data.content;

        // remove redundant fields
        patientsList = patientsList.map((patient) => {
            delete patient.associatedHospitalId;
            delete patient.isArchived;
            delete patient.lastUpdatedAt;
            delete patient.profilePhotoUrl;

            return patient;
        });

        FileUtils.downloadCSV(patientsList, "Patients.csv");
    }

    function archivePatient(patientId) {
        archivePatientAPI.execute(hospitalId, patientId);
    }

    function sendRegistrationEmail(patientId) {
        sendRegistrationEmailAPI.execute(hospitalId, patientId);
    }

    /* API Success Handlers */
    function fetchPatientsHandler(response) {
        let data = response.data;

        pagination.total = data.totalElements;
        setPagination(pagination);

        let patients = [];
        data.content.map((patient, index) =>
            patients.push(
                transformJsonData(
                    patient,
                    index + 1
                )
            )
        );
        setPatientList(patients);
    }

    function archivePatientHandler(response) {
        window.location.reload();
    }

    const handleAllCentresChange = (event) => {
        setAllCentres(event.target.checked);
        if (event.target.checked) {
            fetchPatients(0);
        } else {
            fetchPatients(hospitalId);
        }
    };

    return (
        <div>
            <Button

                className="float-end"
                onClick={() => navigate("add-patient")}
                startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
                variant="contained"
            >
                Add Patient
            </Button>
            <div className="d-flex align-items-center">
                <Checkbox
                    checked={allCentres}
                    onChange={handleAllCentresChange}
                    color="primary"
                />
                <label>All Centres</label>
            </div>
            {fetchPatientsAPI.status === "pending" ? (
                <div className="text-center">
                    <CircularProgress
                        className="text-center"
                        sx={{
                            mt: 50,
                        }}
                    />
                </div>
            ) : (
                <ThemeProvider theme={DATA_TABLE_THEME}>
                    <MUIDataTable
                        title={"Patients"}
                        data={patientList}
                        columns={dataTableColumns}
                        options={dataTableOptions}
                    />
                </ThemeProvider>
            )}
            <PopUpModal ref={popUpModalRef} modalWidth={400}>
                <ConfirmActionModal
                    content="Do you want to confirm archiving the patient?"
                    confirmActionHandler={() => {
                        archivePatient(archivingPatientId);
                        popUpModalRef.current.closeModal();
                    }}
                    onCloseHandler={() => popUpModalRef.current.closeModal()}
                />
            </PopUpModal>
        </div>
    );

    function transformJsonData(
        patient,
        index
    ) {

        return {
            id: patient.id,
            index,
            fullName: patient.firstName + " " + patient.lastName,
            phone: patient.phone,
            email: patient.email,
            dob: patient.dob,
            createdAt: DateUtils.formatDateTime(patient.createdAt),
            actions: <ActionsCell patientId={patient.id} hospitalId={patient.associatedHospitalId} />,
        };
    }

    function ActionsCell({ patientId, hospitalId }) {
        const actions = {
            startItems: [
                {
                    component: <EditIcon fontSize="inherit" color="secondary" />,
                    onClickHandler: () => {
                        dispatch(focusHospital({ id: hospitalId }));
                        navigate(patientId + "/profile");
                    },
                },
                {
                    component: (
                        <Tooltip title="Archive Patient">
                            <ArchiveIcon fontSize="inherit" color="warning" />
                        </Tooltip>
                    ),
                    onClickHandler: () => {
                        setArchivingPatientId(patientId);
                        popUpModalRef.current.openModal();
                    },
                },
            ],
            menuItems: [
                {
                    label: "Send Registration Email",
                    onClickHandler: () => sendRegistrationEmail(patientId),
                },
            ],
        };

        return <TableRowActionsCell actions={actions} />;
    }
}

const dataTableColumns = [
    {
        name: "id",
        label: "Patient ID",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "fullName",
        label: "Full Name",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "email",
        label: "Email ID",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "phone",
        label: "Phone",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "createdAt",
        label: "Created At",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "actions",
        label: "Actions",
        options: {
            filter: false,
            sort: false,
            download: false,
        },
    },
];
