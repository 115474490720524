import { useState } from "react";
import styles from "styles/header.module.css";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Settings from "@mui/icons-material/Settings";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Logout from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material/styles";
import UserService from "services/UserService.js";
import StringUtils from "utils/StringUtils.js";
import Authenticator from "services/Authenticator.js";
import { useNavigate } from "react-router-dom";
import useAPI from "hooks/useAPI.js";
import { useSelector, useDispatch } from "react-redux";
import { selectHospital, focusHospital } from "state/slices/hospitalSlice";
import { selectAuthUser, updateAuthUser } from "state/slices/authUserSlice";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 175,
    },
  },
};

function HospitalHeader() {
  return (
    <div
      id={styles["header"]}
      className="d-flex flex-row no-wrap align-items-center justify-content-end"
    >
      <div className="d-flex flex-row no-wrap align-items-center justify-content-between">
        <HospitalsSelectInput />
        <AddNewDropdown />{" "}
        {/*
           <NotificationButton />
           */}
        <UserDropdown />
      </div>
    </div>
  );
}

function HospitalsSelectInput() {
  const theme = useTheme();
  const [hospitals, setHospitals] = useState([]);

  useAPI(UserService.getHospitals, getHospitalsHandler, false, true);

  const focusedHospital = useSelector(selectHospital);
  const dispatch = useDispatch();

  /* API success handlers */
  function getHospitalsHandler(response) {
    let hospitalList = response.data;

    setHospitals(hospitalList);

    let focusHospitalId = hospitalList[0].id;

    // set focused hospital if alredy selected earlier
    if (cookies.get("hospital")) {
      focusHospitalId = cookies.get("hospital");
    }
    dispatch(focusHospital({ id: focusHospitalId }));
  }

  function getStyles(hospitalId) {
    return {
      fontWeight: focusedHospital.id !== hospitalId ? 400 : 600,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Set hospital id in cookies to persist selected hospital (otherwise the selected hospital changes on page reload)
    cookies.set("hospital", value, { path: "/" });

    dispatch(focusHospital({ id: value }));
  };

  const isSelectDisabled = /^\/hospital\/emr-management\/patients\/\d+\/.+/.test(window.location.pathname);

  return (
    <FormControl
      sx={{
        mr: "14px",
        width: 200,
        height: 40,
      }}
      size="small"
    >
      <InputLabel id="hospital-branches-select-label">Centre</InputLabel>
      <Select
        labelId="hospital-branches-select-label"
        sx={{
          background: theme.palette.background.default,
        }}
        IconComponent={() => (
          <img
            id={styles["map-marker-icon"]}
            src="assets/map-marker-icon.svg"
            alt="Map marker icon"
          />
        )}
        id="hospital-branches-select"
        value={hospitals.length === 0 ? "" : focusedHospital.id}
        onChange={handleChange}
        input={<OutlinedInput label="Branch" />}
        MenuProps={MenuProps}
        disabled={isSelectDisabled}
      >
        {hospitals.map((hospital) => (
          <MenuItem
            key={hospital.id}
            value={hospital.id}
            style={getStyles(hospital.id)}
          >
            {hospital.hospitalName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function AddNewDropdown() {
  let navigate = useNavigate();
  const [anchorAddNewDropdownEl, setAnchorAddNewDropdownEl] = useState(null);
  const addNewDropdownOpen = Boolean(anchorAddNewDropdownEl);
  const handleAddNewDropdownClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorAddNewDropdownEl(event.currentTarget);
  };
  const handleAddNewDropdownClose = () => {
    setAnchorAddNewDropdownEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="add-new-btn"
        aria-controls={addNewDropdownOpen ? "add-new-menu" : undefined}
        aria-expanded={addNewDropdownOpen ? "true" : undefined}
        aria-haspopup="true"
        size="large"
        sx={{
          background: "#005EAB",
          mx: "8px",
        }}
        disableRipple
        onClick={handleAddNewDropdownClick}
      >
        <img src="assets/plus-icon.svg" alt="Add icon" />
      </IconButton>
      <Menu
        id="add-new-menu"
        MenuListProps={{
          "aria-labelledby": "add-new-btn",
        }}
        anchorEl={anchorAddNewDropdownEl}
        open={addNewDropdownOpen}
        onClose={handleAddNewDropdownClose}
        onClick={handleAddNewDropdownClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() =>
            navigate("/hospital/emr-management/patients/add-patient")
          }
        >
          Add Patient
        </MenuItem>
      </Menu>
    </div>
  );
}

function UserDropdown() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAPI = useAPI(UserService.getUser, getUserHandler, false, true);

  const authUser = useSelector(selectAuthUser);
  const dispatch = useDispatch();

  const [isAdmin, setIsAdmin] = useState(false);

  /* API success handlers */

  function getUserHandler(response) {
    dispatch(updateAuthUser(response.data));
    let role = response.data.roles[0];
    if (role.typeEnum === "ORGANIZATION_ADMIN") {
      setIsAdmin(true);
    }
  }

  return (
    <div
      id={styles["user-profile-box"]}
      className="d-flex flex-row no-wrap align-items-center justify-content-between"
    >
      <Avatar
        sx={{
          width: 32,
          height: 32,
        }}
        src={authUser.profilePhoto}
        {...StringUtils.stringAvatar(
          `${authUser.firstName} ${authUser.lastName}`
        )}
      />
      <div
        className="d-flex flex-column justify-content-between ms-2"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            lineHeight: "18px",
          }}
          color={theme.palette.text.dark}
        >
          {`${authUser.firstName} ${authUser.lastName}`}
        </Typography>
        <Typography
          variant="caption"
          mt={0}
          sx={{
            lineHeight: "14px",
          }}
          color={theme.palette.text.light}
        >
          {authUser.email}
        </Typography>
      </div>
      <div>
        <IconButton
          aria-label="more"
          id="profile-btn"
          aria-controls={open ? "profile-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img src="assets/kebab-grey-icon.svg" alt="Profile menu icon" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="profile-menu"
          MenuListProps={{
            "aria-labelledby": "profile-btn",
          }}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <MenuItem onClick={() => navigate("/user/profile")}>
            <ListItemIcon>
              <AccountCircleRoundedIcon fontSize="medium" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <Divider />{" "}
          {isAdmin ? (
            <div>
              <MenuItem onClick={() => navigate("/admin/organization")}>
                <ListItemIcon>
                  <SupervisorAccountRoundedIcon fontSize="medium" />
                </ListItemIcon>
                Admin
              </MenuItem>
              <Divider />
            </div>
          ) : null}
          <MenuItem onClick={() => navigate("/user/settings")}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={() => Authenticator.logout()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

function NotificationButton() {
  return (
    <IconButton
      sx={{
        mx: "8px",
      }}
      onClick={() => {
        alert("clicked");
      }}
    >
      <img
        id={styles["notification-icon"]}
        src="assets/notification-icon.svg"
        alt="Notification icon"
      />
    </IconButton>
  );
}

export default HospitalHeader;
